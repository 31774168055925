class RegistrationSummary {
    selector = '.js-registration-summary';
    listHTML = null;

    constructor() {
        this.listHTML = document.querySelector(this.selector);
    }

    update(id_event, title, date, nb_cobatyste, nb_guests) {
        let elemToUpdate = document.querySelector(this.selector + ' #' + id_event);
        elemToUpdate.classList.remove('hide');
        if (nb_guests == 0 && nb_cobatyste == 0) {
            elemToUpdate.classList.add('hide');
        }
        const detailSummary = this.getDetailSummary(title, date, nb_cobatyste, nb_guests);
        elemToUpdate.innerHTML = detailSummary;
    }

    add(id_event, title, date = null, nb_cobatyste = 0, nb_guests = 0, add_first = false) {

        const existingElem = document.querySelector(this.selector + ' #' + id_event);
        if (existingElem) {
            this.update(id_event, title, date, nb_cobatyste, nb_guests);
            return;
        }

        const detailSummary = this.getDetailSummary(title, date, nb_cobatyste, nb_guests);
        let elem = document.createElement("li");
        elem.id = id_event;
        elem.classList.add("elm");
        if (nb_guests == 0 && nb_cobatyste == 0) {
            elem.classList.add('hide');
        }
        elem.innerHTML = detailSummary;
        if (add_first) {
            this.listHTML.prepend(elem);
        } else {
            this.listHTML.appendChild(elem);
        }
    }

    getDetailSummary(title, date = null, nb_cobatyste, nb_guests) {

        let summary_text = '';
        if (nb_cobatyste > 0) {
            summary_text += '1 ' + registrationWording.cobatyste;
        }
        if (nb_cobatyste > 0 && nb_guests > 0) {
            summary_text += ' + ';
        }
        if (nb_guests > 0) {
            summary_text += nb_guests + ' ' + registrationWording.accompagnants;
        }

        let elem =
            ' <div class="title">' + title;

        if (date) {
            elem += '<span class="date"> (' + date + ')</span>';
        }

        elem += '</div><div class="summary">' + summary_text + '</div>';

        return elem;
    }

    remove(id_event) {
        const elemToRemove = document.querySelector(this.selector + ' #' + id_event);
        if (elemToRemove) {
            elemToRemove.classList.add('hide');
        }
    }
}