function initWebsiteSearch() {

    var $input = $('.overlay-search .js-search-input');
    var $buttonExplore = $('.overlay-search .js-search-form-submit');
    var $searchWrapper = $('.js-search-results');

    // Ajoute une class à la balise form pour annuler le curseur qui clignotte
    $input.focusin(function () {
        $(this).parents('form:first').addClass('focused');
    });

    $buttonExplore.on('click', function () {
        setTimeout(function () {
            $input.focus();
            $('.overlay-search form').addClass('focused');
        }, 500);
    });

    setTimeout(function () {
        // init de algolia
        thuriaSearch.init(
            algolia.application_id,
            algolia.search_api_key,
            JSON.parse(algolia.indexes),
            $input.get(0),
            $searchWrapper.get(0),
            $('.overlay-search').get(0)
        );
    }, 1000);
}


if ($('.overlay-search').length > 0 ) {
    initWebsiteSearch();
}





