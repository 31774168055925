th_maps.onLoad(function () {

    th_maps.addMarkerIcon('default', {
        iconUrl: localizeParams.template_path + '/assets/images/markers/ico-marker-map-2x.png',
        shadowUrl: null,
        iconSize: [16, 16],
        iconAnchor: [8, 8]
    });

    th_maps.addMarkerIcon('hover', {
        iconUrl: localizeParams.template_path + '/assets/images/markers/ico-marker-map-hover-2x.png',
        shadowUrl: null,
        iconSize: [36, 36],
        iconAnchor: [18, 18]
    });

    th_maps.addMarkerIcon('defaultHebergement', {
        iconUrl: localizeParams.template_path + '/assets/images/markers/ico-marker-hebergement-2x.png',
        shadowUrl: null,
        iconSize: [16, 16],
        iconAnchor: [8, 8]
    });

    th_maps.addMarkerIcon('hoverHebergement', {
        iconUrl: localizeParams.template_path + '/assets/images/markers/ico-marker-hebergement-hover-2x.png',
        shadowUrl: null,
        iconSize: [36, 36],
        iconAnchor: [18, 18]
    });
});

th_maps.init(thConfig.map.init);