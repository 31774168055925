(function () {

    class BlocCongresAgenda {
        context = null;
        current = 1;
        _selector = {
            onglet: '.onglet',
            element: '.element',
            index: '[data-index]'
        }
        constructor(groupNode) {
            this.context = groupNode;

            this.watch();
        }

        watch(){
            const onglets = this.context.querySelectorAll(this._selector.onglet);
            onglets.forEach((onglet) => {
                onglet.addEventListener("click", (event) => {
                    const index = onglet.getAttribute('data-index');
                    this.toggle(index);
                });
            });
        }

        toggle(index){
            this.reset();
            const updates = this.context.querySelectorAll('[data-index="'+index+'"]');
            updates.forEach((update) => {
                update.classList.add('current');
            })
        }

        reset(){
            const elements = this.context.querySelectorAll(this._selector.index);
            elements.forEach((element) => {
                element.classList.remove("current");
            });
        }


    }


    // Init des blocs
    const blocs = document.querySelectorAll('.bloc-congres-agenda');
    blocs.forEach(function(bloc) {
        new BlocCongresAgenda(bloc);
    });

})();