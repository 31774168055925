// listing CPT - pas de map
var listingCPT = new thListingManager({
    selectors: {
        listing: '.js-listing-cpt',
        mapoverlay: '.js-listing-overlay-map',
    },
    afterUpdate: function (html) {
        parseCardClickOverlay();
        parseCardAnnuaireClickOverlay();

        let nb_filtres_checked = parseInt($(html).find(listingCPT.options.selectors.nb_filters).text());
        if (nb_filtres_checked > 0) {
            $('.js-listing-btn-showmap')
                .removeAttr('aria-disabled')
                .removeAttr('disabled')
                .removeClass('deactivated-btn');
            th_overlay.parse($('.js-listing-btn-showmap'));
        } else {
            $('.js-listing-btn-showmap')
                .prop('aria-disabled', 'true')
                .prop('disabled', 'true')
                .addClass('deactivated-btn');
        }
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if (listingCPT.loaded) {
                listingCPT.initMapOverlay();
            }
        }, 850);
    }
});

function callbackMapOverlayMap(macarte) {
    macarte.zoomControl.setPosition('topleft');
}

(function () {
    // Boutons "Voir plus", quand texte tronqué sur les blocs "À propos"
    // ==========================================================================
    const blocViewMore = document.querySelectorAll('.js-view-more-card-infodoc');        /* à changer en single-sit sur un vrai projet */
    blocViewMore.forEach(item => {
        const content = item.querySelector('[data-view-more-content]');
        const trigger = item.querySelector('[data-view-more-trigger]');

        // Comportement de viewmore sur le bloc si sa hauteur est suffisante
        if (content && content.getBoundingClientRect().height >= 48) {
            item.classList.add('view-more-active');

            trigger.addEventListener('click', function() {
                item.classList.toggle('is-expanded');
            });
        }
    });
})();
