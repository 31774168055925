(function () {


    if ($('.grid-actions').length > 0){
        var colcade = new Colcade( '.grid-actions', {
            columns: '.grid-col',
            items: '.grid-item'
        });
    }

})();