
//(function () {

    // Init des sliders
    const slidersAncre = document.querySelectorAll('.js-slider-ancres');
    let sliderAncre;        // instance de splide utililisé plus loin dans la lib th_ancres.js
    slidersAncre.forEach(function(slider) {
        sliderAncre = initSlider(slider);
    });

    function initSlider(slider) {
        const splide = new Splide(slider, {
            wheel: true,
            releaseWheel: true,
            height: "321px",
            autoHeight: true,
            pagination: false,
            direction: 'ttb',
            arrows: false,
            breakpoints: {
                980: {
                    direction: 'ltr',
                    height: "60px",
                    arrows: true
                },
            }         // espace pour les flèches de navigation
        }).mount();
        return splide;
    }
//})();
