
if (!navigator.userAgent.match(/Android/i) && !navigator.userAgent.match(/iP(hone|od|ad)/)){

    let options = {
        threshold: 1,
    };


    //Bloc pictogrammes
    const blocPictoSelector = document.querySelectorAll(".bloc-pictogrammes ul");
    if (blocPictoSelector){
        blocPictoSelector.forEach(picto => {
            const blocPictoObserver = new IntersectionObserver((picto) => {
                picto.forEach(entry => {
                    const intersecting = entry.isIntersecting;
                    entry.target.classList.add("animated");
                    if (intersecting){
                        entry.target.classList.add("done");
                    }

                    const children = entry.target.querySelectorAll("li");
                    let delay = 0.15;
                    children.forEach(child => {
                        child.style.transitionDelay = delay+ "s";
                        delay += 0.25;
                    });
                });
            }, options);
            blocPictoObserver.observe(picto);
        });
    }



    const blocRelation = document.querySelectorAll(".bloc-relation");
    if (blocRelation){
        blocRelation.forEach(elm => {
            const blocRelationObserver = new IntersectionObserver((elm) => {
                elm.forEach(entry => {
                    const intersecting = entry.isIntersecting;
                    entry.target.classList.add("animated");
                    if (intersecting){
                        entry.target.classList.add("done");
                    }

                    const children = entry.target.querySelectorAll(".splide__slide");
                    let delay = 0;
                    children.forEach(child => {
                        child.style.transitionDelay = delay+ "s";
                        delay += 0.15;
                    });
                });
            }, options);
            blocRelationObserver.observe(elm);
        });
    }


    const blocCitation = document.querySelectorAll(".bloc-auteur-citation");
    if (blocCitation){
        blocCitation.forEach(elm => {
            const blocAuteurCitationObserver = new IntersectionObserver((elm) => {
                elm.forEach(entry => {
                    const intersecting = entry.isIntersecting;
                    entry.target.classList.add("animated");
                    if (intersecting){
                        entry.target.classList.add("done");
                    }
                });
            }, options);
            blocAuteurCitationObserver.observe(elm);
        });
    }


}