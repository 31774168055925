class Registration {
    registrationPostID = null;
    cobatyste = null;
    guests = {};
    schedule = {};
    formGuest = null;
    formMonetico = null;
    summary = null;
    Visit = null;
    Events = null;
    selectors = {
        page: '.js-registration-page',
        form: '.js-registration-form',
        formRIB: '.js-registration-rib-form',
        errorForm: '.frm_error_style',
        total: '.js-registration-total-price',
        inputTotal: 'input[name="total"]',
        submitBtn: '.js-registration-submit',
        attributePaymentMethod: 'data-js-paymentmethod',
        inputPaymentMethodName: 'payment_mode',
        cobatysteForm: '.js-registration-form-cobatyste',
        cobatysteCheckboxFirstname: '.js-registration-cobatyste-chekboxesfirstname',
        companyNameModale: 'input[name="modale_societe"]',
        companyNameForm: 'input[name="raison_sociale"]'
    };

    total = 0;
    limitNbGuest = 1;

    constructor() {

        if (!document.querySelector(this.selectors.page)) {
            return;
        }

        this.registrationPostID = document.querySelector(this.selectors.form + ' input[name="registration_post_id"]').value;

        this.summary = new RegistrationSummary();
        this.formGuest = new RegistrationFormGuest(this);
        this.Visit = new RegistrationVisit(this);
        this.formMonetico = new RegistrationFormMonetico(this);

        this.addListenersOnce();
        this.loadCobatyste();
        this.loadGuests();

        this.Events = new RegistrationEvent(this);

        this.updateTotal();
        this.addPaymentModaleEvents();
    }

    addListenersOnce() {
        // bouton d'ajout d'un accompagnant
        const addBtn = document.querySelector(this.formGuest.selectors.btnAdd);
        addBtn.addEventListener("click", (e) => {
            e.preventDefault();
            const guests_containers = document.querySelectorAll(this.formGuest.selectors.containerParticipants + ' .participant');
            const id_guest = guests_containers.length;
            if (id_guest == this.limitNbGuest) {
                addBtn.classList.add('hide');
            }
            if (id_guest <= this.limitNbGuest) {
                this.formGuest.add(id_guest);
            }
        });
    }

    loadCobatyste() {
        // init du TomSelect sur le cobatyste
        const ts = new TomSelect('#user_diet', {
            plugins: ['remove_button', 'no_active_items']
        });

        // update du formulaire du cobatyste
        const selector_textarea = this.selectors.cobatysteForm + ' textarea';
        const selector_select = this.selectors.cobatysteForm + ' select';
        let inputs = document.querySelectorAll(selector_textarea + ',' + selector_select);
        inputs.forEach((input) => {
            input.addEventListener('change', (event) => {
                this.saveRegistration();
            })
        });

        this.cobatyste = new RegistrationGuest();
        this.cobatyste.lastname = registrationCobatyste.lastname;
        this.cobatyste.firstname = registrationCobatyste.firstname;
        this.cobatyste.diet = registrationCobatyste.diet;
        this.cobatyste.details = registrationCobatyste.details;
        this.cobatyste.schedule = registrationCobatyste.schedule;

        // update du contenu des visites techniques
        const containersVisits = document.querySelectorAll(this.formGuest.selectors.containerVisitsTechnic);
        containersVisits.forEach((container) => {
            const id_event = container.getAttribute(this.formGuest.selectors.attributeEventID);
            if (typeof this.cobatyste['schedule'] != "undefined") {
                const choosen_visit = this.cobatyste['schedule'][id_event];
                if (choosen_visit) {
                    this.Visit.updateSummaryVisitContent(
                        'overlay-event-' + id_event,
                        id_event,
                        choosen_visit
                    );
                }
            }
        });

        // listener sur les checkbox de participation
        const checkboxes = document.querySelectorAll(this.selectors.cobatysteCheckboxFirstname);
        checkboxes.forEach((node) => {
            node.addEventListener("change", (e) => {
                this.saveRegistration();
            });
        });

        this.updateTotal();
    }

    loadGuests() {
        const guests = registrationCurrentGuests;
        const nbGuests = Object.keys(guests).length;
        if (nbGuests > 0) {

            const addBtn = document.querySelector(this.formGuest.selectors.btnAdd);
            if (nbGuests == this.limitNbGuest) {
                addBtn.classList.add('hide');
            }

            for (const [id_guest, guest] of Object.entries(guests)) {
                let registrationGuest = new RegistrationGuest();
                registrationGuest.id = id_guest;
                registrationGuest.firstname = guest.firstname;
                registrationGuest.lastname = guest.lastname;
                registrationGuest.diet = guest.diet;
                registrationGuest.details = guest.details;
                registrationGuest.schedule = guest.schedule;
                this.guests[id_guest] = registrationGuest;
                this.formGuest.add(id_guest);
                this.formGuest.addCheckboxesFirstname(id_guest);
                this.formGuest.addVisitsContainers(id_guest);
            }
            // pour les images des visites choisies précédemment
            const lazyLoadInstance = new LazyLoad({
                elements_selector: ".lazy"
            });
        } else {
            this.saveRegistration();
        }
    }

    addGuest(id_guest) {
        this.guests[id_guest] = new RegistrationGuest();
        this.guests[id_guest].id = id_guest;
        this.summary.add('entrants', 'Participant(s)', null, 1, Object.keys(this.guests).length, true);
        this.updateTotal();
    }

    updateGuest(guest) {
        this.guests[guest.id] = guest;
        this.formGuest.updateCheckboxFirstname(guest.id);
        this.saveRegistration();
    }

    removeGuest(id_guest) {
        delete this.guests[id_guest];
        this.summary.update('entrants', 'Participant(s)', null, 1, Object.keys(this.guests).length);
        this.updateTotal();
        this.saveRegistration();
    }

    updateTotal() {
        const nb_guests = Object.keys(this.guests).length;

        let total = parseInt(registrationPrices.cobatyste);
        if (nb_guests > 0) {
            total += parseInt(nb_guests * registrationPrices.guest);
        }
        document.querySelector(this.selectors.total).textContent = total + '€';
        document.querySelector(this.selectors.inputTotal).value = total + '€';
        this.formMonetico.updateMontant(total);
        this.formMonetico.updateShoppingCartItemGuest(nb_guests);
    }

    addPaymentModaleEvents() {
        const inputHiddenPaymentMethod = document.querySelector('[name="' + this.selectors.inputPaymentMethodName + '"]');

        const submitBtns = document.querySelectorAll(this.selectors.submitBtn);
        const form = document.querySelector(this.selectors.form);
        const formRIB = document.querySelector(this.selectors.formRIB);
        const error = document.querySelector(this.selectors.formRIB + ' ' + this.selectors.errorForm);

        submitBtns.forEach((btn) => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                const paymentMethod = e.target.getAttribute(this.selectors.attributePaymentMethod);
                inputHiddenPaymentMethod.setAttribute('value', paymentMethod);
                if (!formRIB.checkValidity()) {
                    error.classList.remove('hide');
                } else {
                    error.classList.add('hide');
                    const formRIBData = new FormData(formRIB);
                    for (var input of formRIBData.entries()) {
                        document.querySelector(this.selectors.form + ' input[name="' + input[0] + '"]').value = input[1];
                    }
                    form.submit();
                }
            });
        });
    }

    saveRegistration() {
        let form = document.querySelector(this.selectors.form);

        fetch(ajaxurl, {
            method: 'POST',
            body: new FormData(form),
        })
            .then(response => response.json())
            .then(response => {
                // En cas d'erreur
                if (!response.success) {
                    alert(response.data);
                    return;
                }
                this.cobatyste.schedule = response.data.cobatyste.schedule;
                if (Object.keys(response.data.guests).length > 0) {
                    for (const [id_guest, guest] of Object.entries(response.data.guests)) {
                        this.guests[id_guest].schedule = guest.schedule;
                    }
                }
                this.Events.constructSummary('update');

                // lors de la première ouverture de page et de la création de l'instance d'inscription
                if (!this.registrationPostID) {
                    this.registrationPostID = response.data.registrationPostID;
                    // reference de la commande
                    document.querySelector(this.formMonetico.selectors.reference).value = response.data.reference;

                    // urls de retour
                    document.querySelector(this.selectors.form + ' input[name="registration_post_id"]').value = response.data.registrationPostID;
                    const urlRetourOk = document.querySelector('input[name="url_retour_ok"]');
                    const valRetourOk = urlRetourOk.value;
                    urlRetourOk.value = valRetourOk + this.registrationPostID;
                    const urlRetourEr = document.querySelector('input[name="url_retour_err"]');
                    const valRetourEr = urlRetourEr.value;
                    urlRetourEr.value = valRetourEr + this.registrationPostID;
                }
            });
    }
}