class listingHebergementManager {
    _selector = {
        mapWrapper: '.js-congres-hebergement-map-wrapper',
        map: '.js-congres-hebergement-map',
        listing: '.js-congres-hebergement-listing',
        card: '.card-hebergement',
        congresPOI: '.js-congres-position',
        btnMapOpen: '.js-btn-open-map',
        btnMapClose: '.js-btn-close-map',
    }

    map = null;
    listing = null;
    cards = [];
    markers = [];
    popins = [];
    bounds = [];

    constructor(){
        console.log('construct');
        this.map = document.querySelector(this._selector.map);
        this.listing = document.querySelector(this._selector.listing);

        setTimeout(() => {
            this.initMap();
            this.setCongresPoi();
            this.getCardsData();
        }, "1000");

        this.setMobileBehavior();
    }

    setMobileBehavior(){
        const btnOpenMap = document.querySelector(this._selector.btnMapClose);
        const btnCloseMap = document.querySelector(this._selector.btnMapOpen);

        btnOpenMap.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleMapMobile();
        })
        btnCloseMap.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleMapMobile();
        })
    }

    toggleMapMobile(){
        const mapWrapper = document.querySelector(this._selector.mapWrapper);
        mapWrapper.classList.toggle('open');
    }

    initMap(){
        this.map = th_maps.initSingleMap(document.querySelector(this._selector.map));
    }

    setCongresPoi(){
        const congresPoi = document.querySelector(this._selector.congresPOI);
        const congresLogos = congresPoi.querySelector('img');

        let html = '<div class="marker-pin --congres">';
        if(congresLogos){
            let src = congresLogos.getAttribute('src');
            if (src){
                html += '<img src="'+src+'" class="logo-congres" alt="Logo du congrès">';
            }
        }
        html += '</div>';

        let iconCongres = null;
        iconCongres = 'marker-' + 9999;
        th_maps.addMarkerIcon(iconCongres, {
            className: iconCongres,
            html: html,
            shadowUrl: null,
            iconSize: [80, 92],
            iconAnchor: [40, 92]
        });


        if(congresPoi){
            const congresLat = congresPoi.getAttribute('data-lat');
            const congresLng = congresPoi.getAttribute('data-lng');
            this.markers[9999] = th_maps.createMarker(this.map, {lat: congresLat, lng: congresLng}, iconCongres);

            this.bounds.push(this.markers[9999].getLatLng());
        }
    }

    getCardsData(){
        this.listing.querySelectorAll(this._selector.card).forEach((card, index) => {
            const lat = card.getAttribute('data-lat');
            const lng = card.getAttribute('data-lng');
            const cardData = {
                'index': index,
                'lat': lat,
                'lng': lng,
                'html': card
            }

            this.cards[index] = cardData;

            this.createMarker(this.cards[index]);
        });
    }

    createMarker(card){
        let icon = null;

        icon = 'marker-' + card.index;
        th_maps.addMarkerIcon(icon, {
            className: icon,
            html: '<div class="marker-pin"></div>',
            shadowUrl: null,
            iconSize: [16, 16],
            iconAnchor: [8, 8]
        });

        this.markers[card.index] = th_maps.createMarker(this.map, {lat: card.lat, lng: card.lng}, icon);

        const marker = this.markers[card.index];

        // marker.addEventListener('mouseover', () => this.setMarkerState(marker, 'hoverHebergement') );
        // marker.addEventListener('mouseout', () => this.setMarkerState(marker, 'defaultHebergement') );

        this.bounds.push(marker.getLatLng());

        this.map.fitBounds(this.bounds, {padding: [25, 25]});


        if (!this.isMobile) {
            let card_html = card.html.cloneNode(true);

            console.log(card_html);
            let img = card_html.querySelector('img');
            if(img){
                let trueSrc = img.getAttribute('data-src');
                img.src=trueSrc;
                img.classList.add("loaded");
            }

            this.popins[card.id] = th_maps.createInfoWindow(card_html, marker, 280);
        }
    }
}

const pageCongres = document.querySelector('.js-congres-hebergement');
if(pageCongres){
    const listingHebergement = new listingHebergementManager();
}