class RegistrationGuest {
    id = null;
    firstname = null;
    lastname = null;
    diet = [];
    details = null;
    schedule = {};

    getDisplayName() {
        const lastName = this.lastname;
        if (!lastName) {
            return this.firstname;
        }
        return this.firstname + ' ' + this.lastname.charAt(0).toUpperCase() + '.';
    }
}