/**
 * Gestion du formulaire de saisie des informations pour chacun des guests
 */
class RegistrationFormGuest {

    selectors = {
        btnAdd: '.js-registration-btn-add-guest',
        btnRemove: '.js-registration-btn-remove-guest',
        guestForm: '.js-registration-form-guest',
        containerGuest: '.participant',
        containerParticipants: '.js-registration-container-guest',
        containerCheckboxes: '.js-registration-form-container-chekboxesfirstname',
        containerVisits: '.js-registration-choose-visit',
        containerVisit: '.js-registrastion-visit-guest',
        containerVisitsTechnic: '.js-registration-choose-visit-technic',
        containerVisitTechnic: '.js-registrastion-visit-technic',
        containerEvent: '.js-registration-event',
        attributeGuestID: 'data-js-guestid',
        attributeEventID: 'data-js-eventid',
        guestNumber: '.js-registration-guest-number',
    };

    fields = {};
    Registration = null;
    containerParticipants = null;

    constructor(registration) {
        this.Registration = registration;
        this.fields = registrationFields;
        this.containerParticipants = document.querySelector(this.selectors.containerParticipants);
    }

    initListeners(id_guest) {

        let guest = new RegistrationGuest();
        guest.id = id_guest;

        // gestion du bouton de suppresson du formulaire du participant
        const selectorAttribute = '[' + this.selectors.attributeGuestID + '="' + id_guest + '"]';
        let removeBtn = document.querySelector(this.selectors.btnRemove + selectorAttribute);
        removeBtn.addEventListener("click", (e) => {
            e.preventDefault();
            const id_guest = removeBtn.getAttribute(this.selectors.attributeGuestID);
            this.remove(id_guest);
            if (id_guest <= this.Registration.limitNbGuest) {
                const addBtn = document.querySelector(this.selectors.btnAdd);
                addBtn.classList.remove('hide');
            }
        });

        // Remplissage du formulaire dédié au participant
        const selector_text = this.selectors.guestForm + selectorAttribute + ' input[type="text"]';
        const selector_textarea = this.selectors.guestForm + selectorAttribute + ' textarea';
        const selector_select = this.selectors.guestForm + selectorAttribute + ' select';
        let inputs = document.querySelectorAll(selector_text + ',' + selector_textarea + ',' + selector_select);

        inputs.forEach((input) => {
            input.addEventListener('change', (event) => {
                const type = input.getAttribute('data-js-type');
                const value = event.target.value;

                if (type == "firstname") {
                    if (typeof this.Registration.guests[id_guest] == 'undefined') {
                        guest[type] = value;
                        this.addGuest(id_guest, guest);
                    }
                }
                guest = this.Registration.guests[id_guest];
                guest[type] = value;
                this.Registration.updateGuest(guest);
            });
        });
    }

    addGuest(id_guest, guest) {
        this.Registration.addGuest(id_guest);
        this.Registration.updateGuest(guest);
        this.addCheckboxesFirstname(id_guest);
        this.addVisitsContainers(id_guest);
        this.updateSummary();
    }

    updateSummary() {
        //@TODO mlg
    }

    createFormFieldText(id_guest, type) {
        const label = this.fields[type].label;
        const id = 'guest_' + id_guest + '_' + type;
        let value = "";
        if (typeof this.Registration.guests[id_guest] != "undefined") {
            value = this.Registration.guests[id_guest][type];
        }
        const HTML = '' +
            '  <div class="form-field">' +
            '       <label for="' + id + '" class="frm_primary_label">' + label + '</label>' +
            '       <input type="text" id="' + id + '" name="' + id + '" placeholder="' + label + '" value="' + value + '" data-js-type="' + type + '">' +
            '   </div>';
        return HTML;
    }

    createFormSelectDiet(id_guest, type) {
        const label = this.fields[type].label;
        const id = 'guest_' + id_guest + '_' + type;
        let options = '';
        if (typeof registrationDiets !== 'undefined') {
            registrationDiets.forEach((diet) => {
                let selected = '';
                const guestDiet = typeof this.Registration.guests[id_guest] != 'undefined' ? this.Registration.guests[id_guest].diet : false;
                if (
                    Array.isArray(guestDiet)
                    && guestDiet.includes(String(diet.term_id))
                ) {
                    selected = "selected";
                }
                options += ' <option value="' + diet.term_id + '" ' + selected + '>' + diet.name + '</option>';
            });
        }

        const HTML = '' +
            '  <div class="form-field">' +
            '       <label for="' + id + '" class="frm_primary_label">' + label + '</label>' +
            '       <select id="' + id + '" multiple class="no-selectric tmselect" name="' + id + '[]" data-js-type="' + type + '">' +
            options +
            '       </select>' +
            '   </div>';
        return HTML;
    }

    createFormFieldTextArea(id_guest, type) {
        const label = this.fields[type].label;
        const placeholder = this.fields[type].placeholder;
        const id = 'guest_' + id_guest + '_' + type;
        let value = "";
        if (typeof this.Registration.guests[id_guest] != "undefined") {
            value = this.Registration.guests[id_guest][type];
        }
        const HTML = '' +
            '  <div class="form-field">' +
            '       <label for="' + id + '" class="frm_primary_label">' + label + '</label>' +
            '       <textarea id="' + id + '" name="' + id + '" placeholder="' + placeholder + '" data-js-type="' + type + '">' + value + '</textarea>' +
            '   </div>';
        return HTML;
    }

    createHeadForm(id_guest) {
        const head = '' +
            '<div class="head">' +
            '   <span class="price-tag">' + registrationPrices.guest + '€</span>' +
            '   <span class="title">' + registrationWording.accompagnant + ' n°' +
            '       <span class="js-registration-guest-number">' + id_guest + '</span>' +
            '   </span>' +
            '   <span class="icon-close ' + this.selectors.btnRemove.replace('.', '') + '" ' + this.selectors.attributeGuestID + '="' + id_guest + '"></span>' +
            '</div>';
        return head;
    }

    createForm(id_guest) {

        const headForm = this.createHeadForm(id_guest);
        const bodyOpenForm = '<div class="form-field ' + this.selectors.guestForm.replace('.', '') + '" ' + this.selectors.attributeGuestID + '="' + id_guest + '">';
        const bodyCloseForm = '</div>';

        let fields = '';
        fields += this.createFormFieldText(id_guest, 'firstname');
        fields += this.createFormFieldText(id_guest, 'lastname');
        fields += this.createFormSelectDiet(id_guest, 'diet');
        fields += this.createFormFieldTextArea(id_guest, 'details');

        const fullForm = headForm + bodyOpenForm + fields + bodyCloseForm;
        return fullForm;
    }

    createCheckboxFirstname(id_guest, id_event) {
        let node = document.createElement('label');
        const id = id_event + '_guest_' + id_guest;
        node.setAttribute('for', id);
        node.setAttribute(this.selectors.attributeGuestID, id_guest);

        let checked = "";
        if (typeof this.Registration.guests[id_guest]['schedule'][id_event] != "undefined") {
            const is_checked = this.Registration.guests[id_guest]['schedule'][id_event];
            if (is_checked) {
                checked = 'checked';
            }
        }

        const HTML = '<strong class="js-registration-guest-name">' +
            this.Registration.guests[id_guest].getDisplayName() +
            '</strong>' +
            '   <input type="checkbox"' +
            '           id="' + id + '"' +
            '           name="' + id_event + '_guest[]" ' +
            checked +
            '           value="' + id_guest + '">' +
            '   <span></span>'
        ;
        node.innerHTML = HTML;
        node.addEventListener("change", (e) => {
            this.Registration.saveRegistration();
        });
        return node;
    }

    updateCheckboxFirstname(id_guest) {
        const selectorGuest = '[' + this.selectors.attributeGuestID + '="' + id_guest + '"]';
        const labelsToUpdate = document.querySelectorAll(selectorGuest + " .js-registration-guest-name");
        labelsToUpdate.forEach((span) => {
            span.innerText = this.Registration.guests[id_guest].getDisplayName();
        });
    }

    createVisitContainer(id_guest, id_event) {
        let node = document.createElement('div');
        node.classList.add(this.selectors.containerVisit.replace('.', ''));
        node.setAttribute(this.selectors.attributeGuestID, id_guest);
        let value = "";
        if (typeof this.Registration.guests[id_guest]['schedule'][id_event] != "undefined") {
            value = this.Registration.guests[id_guest]['schedule'][id_event];
        }
        let HTML = '' +
            '<input type="hidden" name="' + id_event + '_guest_' + id_guest + '" value="' + value + '">' +
            '<div class="event__choose-visit">' +
            '   <div class="accompagnant-wrapper">' +
            '       <span class="accompagnant">' + registrationWording.accompagnant + ' n°' +
            '           <span>' + id_guest + ': </span>' +
            '       </span>' +
            '       <span class="name">' + this.Registration.guests[id_guest].getDisplayName() + '</span>' +
            '   </div>' +
            '   <button ' +
            '       data-overlay-open="overlay-event-' + id_event + '" ' +
            '       class="btn" ' +
            this.selectors.attributeGuestID + '="' + id_guest + '"' +
            this.selectors.attributeEventID + '="' + id_event + '"' +
            ' data-overlay-type="visit" ' +
            '>' + registrationWording.choose_visit + '</button>' +
            '</div>' +
            '<p class="event__info-text">' + registrationWording.info + '</p>' +
            '<div class="event__chosen-visits">' +
            '</div>'
        ;
        node.innerHTML = HTML;
        return node;
    }

    addCheckboxesFirstname(id_guest) {
        const containersCheckboxes = document.querySelectorAll(this.selectors.containerCheckboxes);
        containersCheckboxes.forEach((container) => {
            const id_event = container.getAttribute(this.selectors.attributeEventID);
            const checkbox = this.createCheckboxFirstname(id_guest, id_event);
            container.appendChild(checkbox);
        });
    }

    addVisitsContainers(id_guest) {
        const containersVisits = document.querySelectorAll(this.selectors.containerVisits);
        containersVisits.forEach((container) => {
            const id_event = container.getAttribute(this.selectors.attributeEventID);
            const visit = this.createVisitContainer(id_guest, id_event);
            container.appendChild(visit);
            if (typeof this.Registration.guests[id_guest]['schedule'][id_event] != "undefined") {
                const choosen_visit = this.Registration.guests[id_guest]['schedule'][id_event];
                if (choosen_visit) {
                    this.Registration.Visit.updateSummaryVisitContent(
                        'overlay-event-' + id_event,
                        id_event,
                        choosen_visit,
                        id_guest
                    );
                }
            }
        });
        th_overlay.parse();
        const containersEvent = document.querySelectorAll(this.selectors.containerEvent);
        containersEvent.forEach((container) => {
            container.classList.remove('hide');
        });
    }

    removeCheckboxesFirstname(id_guest) {
        const selectorCheckbox = this.selectors.containerCheckboxes + ' label[' + this.selectors.attributeGuestID + '="' + id_guest + '"]';
        const checkboxesGuest = document.querySelectorAll(selectorCheckbox);
        checkboxesGuest.forEach((checkbox) => {
            checkbox.remove();
        });
    }

    removeVisitContainer(id_guest) {
        const selectorContainerVisit = this.selectors.containerVisit + '[' + this.selectors.attributeGuestID + '="' + id_guest + '"]';
        const containerVisit = document.querySelectorAll(selectorContainerVisit);
        containerVisit.forEach((node) => {
            node.remove();
        });
    }

    add(id_guest) {
        const newEmptyForm = document.createElement('div');
        newEmptyForm.classList.add('participant');
        newEmptyForm.setAttribute(this.selectors.attributeGuestID, id_guest);
        newEmptyForm.innerHTML = this.createForm(id_guest);

        const btnAdd = document.querySelector(this.selectors.btnAdd);
        this.containerParticipants.insertBefore(newEmptyForm, btnAdd);

        // init du TomSelect sur le form
        const ts = new TomSelect('#' + 'guest_' + id_guest + '_diet', {
            plugins: ['remove_button', 'no_active_items']
        });

        this.initListeners(id_guest);
    }

    remove(id_guest) {
        const guestContainer = document.querySelector(this.selectors.containerGuest + '[' + this.selectors.attributeGuestID + '="' + id_guest + '"]');
        guestContainer.remove();
        this.removeCheckboxesFirstname(id_guest);
        this.removeVisitContainer(id_guest);
        this.Registration.removeGuest(id_guest);
    }
}