class RegistrationEvent {
    events = {};
    Registration = null;

    constructor(registration) {
        this.Registration = registration;
        this.parseSchedule();
        this.constructSummary();
    }

    parseSchedule() {
        // registrationSchedule: variable définie dans le template twig de la page
        registrationSchedule.forEach((day, num_day) => {
            let tempObj = {};
            let schedule_day = day.schedule;
            for (const [num_event, agenda] of Object.entries(schedule_day)) {
                tempObj[num_event] = agenda;
            }
            this.events[num_day] = tempObj;
        });
    }

    constructSummary(action = 'add') {
        registrationSchedule.forEach((day, num_day) => {
            let schedule_day = day.schedule;
            for (const [num_event, agenda] of Object.entries(schedule_day)) {
                this.saveSummary(num_day, day, num_event, agenda, action);
            }
        });
    }

    saveSummary(num_day, day, num_event, agenda, action) {
        const id_event = num_day + '_' + num_event;
        const id_event_summary = 'event_' + id_event;
        const event = this.getEvent(id_event);
        let nb_cobatyste = 0;
        let nb_guest = 0;

        switch (event.entrant) {
            case 'auto':
                nb_cobatyste = 1;
                nb_guest = Object.keys(this.Registration.guests).length;
                break;
            case 'cobatyste':
                if (
                    typeof this.Registration.cobatyste.schedule != 'undefined'
                    && typeof this.Registration.cobatyste.schedule[id_event] != 'undefined'
                    && this.Registration.cobatyste.schedule[id_event]
                ) {
                    nb_cobatyste = 1;
                }
                break;
            case 'both':
                if (
                    typeof this.Registration.cobatyste.schedule != 'undefined'
                    && typeof this.Registration.cobatyste.schedule[id_event] != 'undefined'
                    && this.Registration.cobatyste.schedule[id_event]) {
                    nb_cobatyste = 1;
                }
                if (Object.keys(this.Registration.guests).length > 0) {
                    for (const [id_guest, guest] of Object.entries(this.Registration.guests)) {
                        if (typeof guest.schedule[id_event] != 'undefined' && guest.schedule[id_event]) {
                            nb_guest++;
                        }
                    }
                }
                break;
            case 'guest':
                if (Object.keys(this.Registration.guests).length > 0) {
                    for (const [id_guest, guest] of Object.entries(this.Registration.guests)) {
                        if (typeof guest.schedule[id_event] != 'undefined' && guest.schedule[id_event]) {
                            nb_guest++;
                        }
                    }
                }
                break;
        }

        const date = moment.unix(day.date).format("DD/MM/YYYY");

        if (action == "add") {
            this.Registration.summary.add(id_event_summary, agenda.title, date, nb_cobatyste, nb_guest);
        }
        if (action == "update") {
            this.Registration.summary.update(id_event_summary, agenda.title, date, nb_cobatyste, nb_guest);
        }
    }

    getEvent(id_event) {
        let eventParts = id_event.split('_');
        const event = this.events[eventParts[0]][eventParts[1]];
        if (typeof event == 'undefined') {
            return false;
        }
        return event;
    }

    getEventTypeVisit(id_event) {
        const event = this.getEvent(id_event);
        if (!event) {
            return false;
        }
        if (event.type != "visit") {
            return false;
        }
        if (event.entrant == "guest") {
            return 'guest';
        }
        return 'cobatyste';
    }
}