class RegistrationVisit {
    Registration = null;

    selectors = {
        overlayHeaderGuestID: ".js-registration-overlay-header-guest-id",
        overlayHeaderGuestName: ".js-registration-overlay-header-name",
        overlaySubmiBtn: ".js-registration-overlay-submitbtn"
    };

    constructor(registration) {
        this.Registration = registration;
        this.overlayCallback();
    }

    overlayCallback() {
        let that = this;
        th_overlay.addCallbackOpen(function (overlay_id, data) {
            if (typeof data != 'undefined' && typeof data['overlayType'] != "undefined" && data['overlayType'] == 'visit') {
                that.updateOverlayContent(overlay_id, data);
            }
        });
    }

    updateOverlayContent(overlay_id, data) {

        const id_event = data['jsEventid'];
        let id_guest = null;
        const typeEvent = this.Registration.Events.getEventTypeVisit(id_event);

        if (!typeEvent) {
            return;
        }

        const selectorAttrEvent = '[' + this.Registration.formGuest.selectors.attributeEventID + '="' + id_event + '"]';
        let nameInputHidden = id_event + '_visit_cobatyste';
        let selectorInputHidden = selectorAttrEvent + ' input[name="' + nameInputHidden + '"]';

        if (typeEvent == "guest") {
            id_guest = data['jsGuestid'];
            const guest = this.Registration.guests[id_guest];

            document.querySelector('#' + overlay_id + " " + this.selectors.overlayHeaderGuestID).innerText = id_guest;
            document.querySelector('#' + overlay_id + " " + this.selectors.overlayHeaderGuestName).innerText = guest.getDisplayName();

            const selectorAttrGuest = '[' + this.Registration.formGuest.selectors.attributeGuestID + '="' + id_guest + '"]';
            nameInputHidden = id_event + '_guest_' + id_guest;
            selectorInputHidden = selectorAttrEvent + ' ' + selectorAttrGuest + ' input[name="' + nameInputHidden + '"]';
        }

        let submitBtn = document.querySelector('#' + overlay_id + ' ' + this.selectors.overlaySubmiBtn);
        submitBtn.addEventListener("click", (e) => {
            e.preventDefault();
            const radio_checked = document.querySelector('#' + overlay_id + ' input[type="radio"]:checked');
            let input_hidden = document.querySelector(selectorInputHidden);
            input_hidden.value = radio_checked.value;
            this.updateSummaryVisitContent(overlay_id, id_event, radio_checked.value, id_guest);
            this.Registration.saveRegistration();
            th_overlay.close(overlay_id);
        }, {
            'once': true //  suppression du listener une fois qu'il a été appelé, très important !!!!
        });
    }

    updateSummaryVisitContent(overlay_id, id_event, choosenVisit, id_guest = null) {
        const selectorVisitNode = '#' + overlay_id + ' [data-js-visit-id="' + choosenVisit + '"]';
        const title = document.querySelector(selectorVisitNode + ' .title-card').innerHTML;

        const nodeDesc = document.querySelector(selectorVisitNode + ' .description p');
        let htmlDesc = '';
        if (nodeDesc) {
            htmlDesc = '<div class="description">' + nodeDesc.innerHTML + '</div>';
        }

        const nodeNbPlaces = document.querySelector(selectorVisitNode + ' .js-nb-places');
        let htmlNbPlaces = '';
        if (nodeNbPlaces) {
            htmlNbPlaces = '' +
                '<div class="left">' +
                '   <span class="icon-info"></span> ' + nodeNbPlaces.innerHTML +
                '</div>';
        }

        const nodeImg = document.querySelector(selectorVisitNode + ' .image');
        let htmlImg = '<div class="image"><div class="no-photo"></div></div>';
        if (nodeImg) {
            htmlImg = '<div class="image">' + nodeImg.innerHTML + '</div>';
        }

        let HTML = '' +
            '<div class="card card-horizontal">' +
            htmlImg +
            '   <div class="caption">' +
            '       <span class="title-card">' + title + '</span>' +
            htmlDesc +
            // '       <div class="additional">TODO</div>' +
            htmlNbPlaces
        '   </div>' +
        '</div>';

        const selectorAttrEvent = '[' + this.Registration.formGuest.selectors.attributeEventID + '="' + id_event + '"]';
        let selectorContainerVisit =
            this.Registration.formGuest.selectors.containerVisitsTechnic +
            selectorAttrEvent +
            ' ' +
            this.Registration.formGuest.selectors.containerVisitTechnic +
            ' .event__chosen-visits';

        if (id_guest) {
            const selectorAttrGuest = '[' + this.Registration.formGuest.selectors.attributeGuestID + '="' + id_guest + '"]';
            selectorContainerVisit =
                this.Registration.formGuest.selectors.containerVisits +
                selectorAttrEvent +
                ' ' +
                this.Registration.formGuest.selectors.containerVisit +
                selectorAttrGuest +
                ' .event__chosen-visits';
        }

        let containerVisit = document.querySelector(selectorContainerVisit);
        containerVisit.innerHTML = HTML;
    }
}