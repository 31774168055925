(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-cover-home');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            pagination: false,
            arrows: false,
            autoHeight: true,
            autoplay: true,
            interval: 4000,
            type: 'loop',
        }).mount();
    });

    const slidersMultiple = document.querySelectorAll('.js-slider-cover-home-multiples');
    slidersMultiple.forEach(slider => {
        const splide = new Splide(slider, {
            pagination: false,
            arrows: true,
            autoHeight: true,
            autoplay: false,
        }).mount();
    });

})();