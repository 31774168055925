class RegistrationFormMonetico {

    selectors = {
        form: '.js-registration-monetico-form',
        date: 'input[name="date"]',
        context: 'input[name="contexte_commande"]',
        seal: 'input[name="MAC"]',
        reference: 'input[name="reference"]',
        montant: 'input[name="montant"]',
        submitBtn: '.js-registration-monetico-submit',
        countrySelect: '.js-registration-country-select',
        errorForm: '.frm_error_style'
    };

    billing = {
        civility: '',
        firstName: '',
        lastName: '',
        addressLine1: '',
        city: '',
        postalCode: '',
        country: '',
    };

    shoppingCart = {
        shoppingCartItems: []
    };

    shoppingCartItemCobatyste = {
        name: 'Cobatyste',
        unitPrice: '',
        quantity: 0,
    };

    shoppingCartItemGuest = {
        name: 'Invité(s)',
        unitPrice: '',
        quantity: 0,
    };

    contextOrder = {
        billing: null,
        shoppingCart: null,
    };

    Registration = null;

    constructor(registration) {
        this.initCountrySelect();
        this.Registration = registration;

        this.shoppingCartItemCobatyste.unitPrice = registrationPrices.cobatyste;
        this.shoppingCartItemGuest.unitPrice = registrationPrices.guest;
        this.shoppingCart.shoppingCartItems.push(this.shoppingCartItemCobatyste);
        this.initListeners();
    }

    initCountrySelect() {
        new TomSelect(this.selectors.countrySelect, {
            maxItems: null,
            maxOptions: 100,
            valueField: 'code',
            labelField: 'name',
            searchField: 'name',
            sortField: 'name',
            options: countries,
            create: false
        });
    }

    initListeners() {
        const submitBtn = document.querySelector(this.selectors.form + ' ' + this.selectors.submitBtn);
        submitBtn.addEventListener("click", (e) => {
            e.preventDefault();
            const form = document.querySelector(this.selectors.form);
            const error = document.querySelector(this.selectors.form + ' ' + this.selectors.errorForm);
            if (form.checkValidity()) {
                error.classList.add('hide');
                this.updateContextOrder();
            } else {
                error.classList.remove('hide');
            }
        });
    }

    updateMontant(total) {
        document.querySelector(this.selectors.form + ' ' + this.selectors.montant).value = total + 'EUR'
    }

    updateContextOrder() {
        const inputHiddenPaymentMethod = document.querySelector('[name="' + this.Registration.selectors.inputPaymentMethodName + '"]');
        inputHiddenPaymentMethod.setAttribute('value', 'monetico');
        this.Registration.saveRegistration();

        const form = document.querySelector(this.selectors.form);
        const submitter = document.querySelector(this.selectors.submitBtn);
        let formData = new FormData(form, submitter);

        let fields = {};
        for (const [key, value] of formData) {
            if (typeof this.billing[key] != 'undefined') {
                this.billing[key] = value;
            } else {
                if (key != 'MAC' && key != 'contexte_commande' && key != 'date') {
                    fields[key] = value;
                }
            }
        }

        this.shoppingCart.shoppingCartItems.push(this.shoppingCartItemGuest);

        this.contextOrder.billing = this.billing;
        // @TODO : on verra plus tard car champs optionnel, mais en l'état actuel le format ne semble pas convenir
        //this.contextOrder.shoppingCart = this.shoppingCart.shoppingCartItems;

        //document.querySelector(this.selectors.form + ' ' + this.selectors.context).value = btoa(JSON.stringify(this.contextOrder));

        // WP ne supporte pas JSON request en ajax, on est donc obligé de tout passer en formData
        formData.append('action', 'handle_prepare_payment_monetico_request');
        formData.append('fields', JSON.stringify(fields));
        formData.append('contextOrder', JSON.stringify(this.contextOrder));
        formData.append('post_id', this.Registration.registrationPostID);

        fetch(ajaxurl, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                // En cas d'erreur
                if (!response.success) {
                    alert(response.data);
                    return;
                }

                document.querySelector(this.selectors.form + ' ' + this.selectors.context).value = response.data.contexte_commande;
                document.querySelector(this.selectors.form + ' ' + this.selectors.seal).value = response.data.MAC;
                document.querySelector(this.selectors.form + ' ' + this.selectors.date).value = response.data.date;
                document.querySelector(this.selectors.form).submit();
            });
    }

    updateShoppingCartItemGuest(nb_guests) {
        this.shoppingCartItemGuest.quantity = nb_guests;
    }
}